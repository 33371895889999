import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {
    Button,
    Container,
    TextInput,
    DatePicker,
    H5,
    ConfirmationBox,
    IconButton
} from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { convertDateToDBFormat } from '../../util/index.js'
import { selectEmail, selectUserDetails } from '../../reducers/authReducer.js';
import { getEnableLitmosSubmit } from '../../reducers/dashboardReducer.js'
import moment from 'moment';
import Modal from 'react-modal';
import Label from '../../components/ncss/Label.js';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import PdfPreview from '../../util/PdfPreview.js';

const validateCertificateId = value => value && !/^[0-9]{9}$/.test(value) &&
    'Invalid input, expect 9 digits.'
const maxSize = 5 * 1024 * 1024; // 5MB in bytes

export class Litmos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitModal: false,
            TrainingFiles: [],
            filePreviews: [],
            modalIsOpen: false,
            selectedFile: null,
            onlyAttachment:true,
            uploadedFiles: [],
            isPopoverOpen: false,
            errorMessage: '',
            totalFileSize: 0
        };

    }

    base64Size = (base64) => {
        let stringLength = base64.length - 'data:application/pdf;base64,'.length;
        let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
        return sizeInBytes;
    }

    handleFileChange = (e) => {
        let files = Array.from(e.target.files);
        let totalFileSize =0;
        this.state.uploadedFiles.forEach(file => {
            totalFileSize += this.base64Size(file.base64);
        });
        // Calculate the total size of the existing base64 encoded files
        this.state.filePreviews.forEach(file => {
            totalFileSize += this.base64Size(file.base64);
        });
            // Calculate the total size of the new files
        for (const file of files) {
            totalFileSize += file.size;
        }
        if (files.some(file => file.type !== 'application/pdf')) {
            this.setState({ errorMessage: 'Upload will accept only PDF files.' });
            return;
        } else {
            this.setState({ TrainingFiles: [...this.state.TrainingFiles, ...files], errorMessage: '' });
            files.forEach(file => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    this.setState(prevState => ({
                        filePreviews: [...prevState.filePreviews, { fileName: file.name, base64: reader.result }],
                        totalFileSize: totalFileSize
                    }));
                };
                reader.readAsDataURL(file);
            });
        }
        if (totalFileSize > maxSize) {
            this.setState({ errorMessage: 'Total file size exceeds the 5MB limit. Please upload smaller files.',totalFileSize: totalFileSize });
        }else{
            this.setState({ errorMessage: '' });
        }
        // Reset the value of the file input
        e.target.value = null;
    }

    submitLitmosData = (resubmit) => {
        const { litmosInfo, submitLitmos, email, shouldEnableLitmosSubmit, userDetails } = this.props;
        const { filePreviews,onlyAttachment,uploadedFiles } = this.state;
        shouldEnableLitmosSubmit(true);
        const reqObj = {};
        if(!onlyAttachment){
        reqObj.certificate = litmosInfo.certificateId;
        reqObj.completion = convertDateToDBFormat(litmosInfo.completion) || '';
        const expirationDate = litmosInfo.completion; // expiration date = completion + 2years
        expirationDate.setFullYear(new Date(moment(litmosInfo.completion).utc().format('DD-MMM-YYYY')).getFullYear() + 2);
        reqObj.expiration = convertDateToDBFormat(expirationDate);
        if(userDetails.litmos !== null){ 
            reqObj.renew = resubmit;
            reqObj.uploadId =userDetails.litmos.id
        } 
        reqObj.attachment = [...filePreviews, ...uploadedFiles];
    }
        if(onlyAttachment){
        reqObj.onlyAttachment = true; 
        reqObj.attachment = [...filePreviews, ...uploadedFiles];
        reqObj.uploadId =userDetails.litmos.id;  
        }
        reqObj.email = email;
        submitLitmos(reqObj);
        this.setState({onlyAttachment: true});

    }
    closeSubmitModal = () => {
        this.setState({ submitModal: false });
    };
    openConfirmationPopup = () => {
        this.setState({ submitModal: true });
    }
    displayPopOver = () => {
        const { isPopoverOpen } = this.state;
        const togglePopover = !isPopoverOpen;
        this.setState({ isPopoverOpen: togglePopover });
    };
    shouldEnableReSubmit = () => {
        const { totalFileSize } = this.state;
        return totalFileSize < maxSize ? true : false
    }
    shouldEnableSubmit = () => {
        const { litmosInfo, litmosSubmit, invalid } = this.props;
        const { totalFileSize } = this.state;
        if (litmosInfo.certificateId !== '' && litmosInfo.completion !== "" && !litmosSubmit && !invalid && totalFileSize < maxSize ) {
            return true
        }
        return false
    }
    openModal = (file) => {
        this.setState({ modalIsOpen: true, selectedFile: file });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false, selectedFile: null });
    }
    handleDeleteExistingFile = (fileToDelete) => {
        this.props.clearAttachment()
        const { uploadedFiles } = this.state;
        const index = uploadedFiles.findIndex(file => file.fileName === fileToDelete);
        if (index > -1) {
            let files = [...uploadedFiles];
            let fileSize = this.state.totalFileSize - this.base64Size(uploadedFiles[index].base64);
            files.splice(index, 1);
            this.setState({ uploadedFiles: files, totalFileSize: fileSize })
                if (fileSize > maxSize) {
                    this.setState({ errorMessage: 'Total file size exceeds the 5MB limit. Please upload smaller files.',totalFileSize: fileSize });
                } else {
                    this.setState({ errorMessage: '',totalFileSize: fileSize });
                }
        }
    }
    handleDelete = (index) => {
        let files = [...this.state.filePreviews];
        let fileSize = this.state.totalFileSize - this.base64Size(files[index].base64);
        files.splice(index, 1);
        if(fileSize > maxSize){
            this.setState({ errorMessage: 'Total file size exceeds the 5MB limit. Please upload smaller files.',totalFileSize: fileSize });
        }else{
            this.setState({ errorMessage: '',totalFileSize: fileSize });
        }
        this.setState({ filePreviews: files });
    }
    componentDidUpdate(prevProps) {
        // Check if certificateId or completion date has changed
        if (this.props.litmosInfo.certificateId !== prevProps.litmosInfo.certificateId ||
            this.props.litmosInfo.completion !== prevProps.litmosInfo.completion) {
            if (this.state.onlyAttachment) {
                this.setState({ onlyAttachment: false });
            }
        }

        // Update state with new props
        if (this.props.previewAttachmentData !== prevProps.previewAttachmentData) {
            const { previewAttachmentData } = this.props;
            if (previewAttachmentData.data && !this.state.uploadedFiles.some(file => previewAttachmentData.data.fileName === file.fileName)) {
                let content = previewAttachmentData.data.Content;
                content = "data:application/pdf;base64," + content;
                this.setState({
                    uploadedFiles: [...this.state.uploadedFiles, { fileName: previewAttachmentData.data.fileName, base64: content }]
                });
            }
        }
    }
    
    componentDidMount() {
        const { previewAttachment, userDetails } = this.props;
        userDetails.fileNames && userDetails.fileNames.forEach(fileName => {
            fileName !== null && previewAttachment({ id: userDetails.litmos.id, formType: 'trainingCertificate', userId: userDetails.userId, fileName: fileName });
        });

       
        if (this.props.previewAttachmentData.data) {
            const { previewAttachmentData } = this.props;
            let content = previewAttachmentData.data.Content;
            content = "data:application/pdf;base64," + content;
            this.setState({
                uploadedFiles: [...this.state.uploadedFiles, { fileName: previewAttachmentData.data.fileName, base64: content }]
            });
        }
    }

    render() {
        const { userDetails, initialValues } = this.props;
        const { submitModal, uploadedFiles, filePreviews, isPopoverOpen, errorMessage, selectedFile } = this.state
        if (userDetails.certificateId !== "") {
            initialValues.certificateId = userDetails.certificateId
            initialValues.completion = new Date(userDetails.completion)
        }
        return (
            <form >
                <Container extraClassName="ncss-col-sm-12  display-flex" section>
                    <div className="ncss-col-sm-8">
                        <div className=" ncss-input-container ">
                            <H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm">Certificate ID#<span className="astericks">&#42;</span></H5>
                            <div className="ncss-col-sm-6"><Field component={TextInput} name="certificateId" type="text" validate={validateCertificateId} /></div>
                        </div>
                        <div><H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm mt2-sm">Completion Date<span className="astericks">&#42;</span></H5>
                            <div className="ncss-col-sm-6">
                                <Field
                                    component={DatePicker}
                                    defaultValue={initialValues && initialValues.completion !== '' ? initialValues.completion : null}
                                    showTime={false}
                                    name="completion"
                                />
                            </div>
                        </div>
                        <div className='displayFlex'>
                            <H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm">Upload Certificate(s)
                                <Popover
                                    isOpen={isPopoverOpen}
                                    position="bottom"
                                    padding={5}
                                    onClickOutside={this.displayPopOver}
                                    content={({ position, targetRect, popoverRect }) => (
                                        <ArrowContainer
                                            position={position}
                                            targetRect={targetRect}
                                            popoverRect={popoverRect}
                                            arrowColor="#dddcde"
                                            arrowSize={0}
                                            arrowStyle={{ opacity: 0.7, left: '120px' }}
                                        >
                                            <IconButton icon="times" className="popover-closeButton" onClick={this.displayPopOver} />
                                            <div className="popover-trfsection">

                                                <ol className="ncss-list-ol ol-font-size">
                                                    <li className="ncss-li">
                                                        Upload all the relevant certificates.
                                                    </li>
                                                </ol>
                                            </div>
                                        </ArrowContainer>
                                    )}
                                >
                                    <span className='info-box'>
                                        <IconButton icon="comment-alt" extraClassName="color-trf" onClick={this.displayPopOver} />
                                    </span>
                                </Popover>
                            </H5>

                            <div className="ncss-col-sm-6 mt-10">
                            <input className='invisible' type="file" id="fileInput" onChange={this.handleFileChange} accept='.pdf' />
                            <Label htmlFor="fileInput" className="custom-file-upload pl4-sm pr4-sm u-bold description">
                                Browse...
                            </Label> 
                            <H5 className="description" >
                            To fully complete your profile, upload your Training Certificare pdf file here.
                            </H5>
                            </div>
                        </div>
                        {errorMessage ? (<div className="error-message text-color-red">{errorMessage}</div>) : ''}
                            {filePreviews.map((filePreview, index) => (
                                <div key={index} class='displayFlex padTop12'>
                                    <div className="ncss-col-sm-6 commentStyle"><h6 className='fontSize14'>{filePreview.fileName}</h6></div>
                                    <div className=" ncss-col-sm-6">
                                    <div className="ncss-col-sm-3">
                                        <span className='fileSizeText'> {(this.base64Size(filePreview.base64) / 1024).toFixed(2)} KB</span>
                                    </div>
                                    <div className="ncss-col-sm-1 padRight20">
                                        <i className="fas fa-eye" alt="Preview" onClick={() => this.openModal(filePreview)} />
                                    </div>
                                    <div className="ncss-col-sm-1 padRight20"><a href={filePreview.base64} download={filePreview.fileName}>
                                        <i className='fa fa-download download-fontSize' alt="Download" />
                                   </a></div>
                                   <div className="ncss-col-sm-1 padRight20"> 
                                        <i className="fas fa-trash" onClick={() => this.handleDelete(index)}/>
                                    </div>
                                    </div>
                                </div>
                            ))}
                            {userDetails.fileNames !== null && uploadedFiles.map((filePreview, index) => (
                                filePreview !== null && 
                                <div key={index}  class='displayFlex padTop12'>
                                    <div className="ncss-col-sm-6 commentStyle"><h6 className='fontSize14'>{filePreview.fileName}</h6></div>
                                    <div className="ncss-col-sm-6">
                                    <div className="ncss-col-sm-3">
                                        <span className='fileSizeText'> {(this.base64Size(filePreview.base64) / 1024).toFixed(2)} KB</span>
                                    </div>
                                    <div className="ncss-col-sm-1 padRight20">
                                     <i className="fas fa-eye" alt="Preview" onClick={() => this.openModal(filePreview)} />
                                    </div>
                                    <div className="ncss-col-sm-1 padRight20"><a href={filePreview.base64} download={filePreview.fileName}>
                                        <i className='fa fa-download download-fontSize' alt="Download" />
                                   </a></div>
                                <div className="ncss-col-sm-1 padRight20">
                                    <i className="fas fa-trash" onClick={() => this.handleDeleteExistingFile(filePreview.fileName)}/>
                                </div>
                                </div>
                                </div>
                            ))}
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            contentLabel="File Preview"
                            overlayClassName="modal-overlay"
                        >
                       {selectedFile && (<div>
                        <div className="ncss-col-sm-12">
                            <IconButton icon="times" className="modal-close-btn" onClick={this.closeModal} />
                        </div>
                           
                        <div className="ncss-col-sm-12">
                         <PdfPreview file={selectedFile.base64 ? selectedFile.base64 : ''} />                      
                         </div>
                         <div className="ncss-col-sm-12">
                            <button onClick={this.closeModal}>Close</button>
                            </div> </div>)}
                        </Modal>
                        <div className="ncss-col-sm-12">
                        {userDetails.litmos === '' || userDetails.litmos === null ?
                            <><div className="ncss-col-sm-6"></div>
                            <Button
                                className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected mt2-sm ${this.shouldEnableSubmit() ? '' : 'disabled'}`}
                                onClick={() => { this.submitLitmosData() }}
                            >SUBMIT
                            </Button></>
                            :<><div className="ncss-col-sm-4"></div>
                            <Button
                                className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected mt2-sm ${this.shouldEnableReSubmit() ? '' : 'disabled'}`}
                                onClick={() => { this.openConfirmationPopup() }}
                            >RE-SUBMIT
                            </Button>{userDetails.LitmosStatus !== "completed" && userDetails.fileNames[0] !== null &&<h6 className='commentStyle ncss-col-sm-4 pt2-sm'><span className="astericks">&#42;</span><span className="astericks">&#42;</span>
                            Allow 7-10 working days for processing. Do not re-submit unless you are instructed to do so by RSL Support</h6>}</> }
                            </div>   
                    </div>
                    <div className="ncss-col-sm-4 commentStyle" >
                        <h6 className='pb2-sm'>For questions on RSL training contact <strong>Chemistry.Training@nike.com</strong></h6>
                        <h6 className='pb2-sm' ><strong>Note: </strong> 
                         Make sure to use the exact same email address for Training as for your RSL Testing Application profile.
                         </h6>
                    </div>
                </Container>
                <div className="ncss-col-sm-12">
                    <h6 className="u-bold support-link">For any other questions,please reach out to <span className='text-color-accent'>RSLSupport@nike.com</span></h6>
                </div>
                <ConfirmationBox
                    message={
                        'Do you want to Re-submit Litmos ?'
                    }
                    submitModal={submitModal}
                    submitValue={
                        () => {
                            this.submitLitmosData(true);
                        }
                    }
                    closeModal={this.closeSubmitModal}
                />
            </form>

        )
    }
}

Litmos.propTypes = {

};

const mapStateToProps = state =>({
    litmosInfo: getFormValues('Litmos')(state),
    email: selectEmail(state),
    userDetails: selectUserDetails(state),
    litmosSubmit: getEnableLitmosSubmit(state),
    previewAttachmentData: state.previewAttachment.previewAttachmentData
})
   

const mapDispatchToProps = dispatch => ({
    submitLitmos: data => {
        dispatch(createServiceAction('addLitmos', 'requested')(data));
    },
    shouldEnableLitmosSubmit: (data) => {
        dispatch(createServiceAction('enableLitmos', 'requested')(data));
    },
    previewAttachment: data => {
        dispatch(createServiceAction('previewAttachment', 'requested')(data));
    },
    clearAttachment: data => {
        dispatch(createServiceAction('clearAttachment', 'requested')(data));
    }
});
export default compose(
    reduxForm({
        form: 'Litmos',
        initialValues: {
            completion: '',
            certificateId: "",
            id: "",
            fileNames: []
        },
        enableReinitialize: true,
         destroyOnUnmount: false
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Litmos);