import React, { useMemo, useCallback, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Collapsible, PageHeader, PageTitle } from '../../../components/index.js';
import { isSupplierUser, isLabUser, isRslUser, isFactoryUser, isNloUser } from '../../../reducers/authReducer.js';
const SupplierGuide = lazy(() => import('./SupplierGuide'));
const PdfViewer = React.lazy(() => import('../../../components/pdfViewer/pdfViewer.js'));

const style = {
  fontWeight: '800',
  fontSize: '1.8rem',
  lineHeight: '1.5',
  color: '#0078B9'
};
const lanStyle={
  fontWeight: '800',
  fontSize: '1.8rem',
  color: '#0078B9'
}

const Training = ({ isSupplierUser, isRslUser, isFactoryUser, isLabUser, isNloUser }) => {

  const renderCollapsibleLab = (fileName, containerId) => (
    <React.Suspense fallback={<div>Loading...</div>}>
      <PdfViewer fileName={fileName} containerId={containerId} />
    </React.Suspense>
  );
  const memoizedRenderCollapsibleLab = useCallback(renderCollapsibleLab, []);

  const collapsiblesLab = useMemo(() => [
    {  language: 'Lab Users Training Guide', fileName: '../training/RSL_Testing_Application_LAB_HowToGuide_Dec2023.pdf', containerId: 'lab' },
    {  language: 'Action Center – OSH Guide', fileName: '../training/Action_Center - OSH_Guide.pdf', containerId: 'actionCenter'},
  ], []);

  return useMemo(() => (
    <div className="userpage">
      <div className="pdf-align-items pdf-page-bottom-align">
        <PageTitle title="How To Guide" />
        <PageHeader title="How To Guide">
          <div className="user-page">
            <i className="fas fa-file-pdf"></i>
          </div>
        </PageHeader>
        {(isFactoryUser || isSupplierUser || isRslUser || isNloUser || isLabUser) && (
                <Suspense fallback={<div>Loading Supplier Guide...</div>}>
                    <SupplierGuide />
                </Suspense>
            )}  
          {isLabUser || isRslUser || isNloUser ? (
              <Suspense fallback={<div>Loading Lab User Guide...</div>}>
            <div>
            <Collapsible  className="training_color" header="collapse"

              trigger={
                <span>
                  <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-flask"></i></span>
                  </div>
                  <div className="ncss-col-sm-11 collapse-p0">
                    <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Lab Users Training Guide
                    </div>
                    <div className="ncss-col-sm-1 ">
                      <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                    </div>
                  </div>
                </span>
              }
              triggerWhenOpen={
                <span>
                  <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-flask"></i></span>
                  </div>
                  <div className="ncss-col-sm-11 collapse-p0">
                    <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Lab Users Training Guide
                    </div>
                    <div className="ncss-col-sm-1 ">
                      <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                    </div>
                  </div>
                </span>
              }
              triggerStyle={lanStyle}
             // triggerClassName="collapsible"
            >
              <div id="lab"></div>
              {collapsiblesLab.filter(ele =>  ele.containerId === 'lab').map(({ fileName, containerId }) => memoizedRenderCollapsibleLab(fileName, containerId))}
            </Collapsible>
          </div></Suspense>
          ) : null} 
          {isFactoryUser || isSupplierUser || isRslUser? (
            <Suspense fallback={<div>Loading Action-center Guide...</div>}>
              <div>
             <Collapsible className="training_color"

               trigger={
                 <span>
                   <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> 
                     <span className="training_page text-size-new pr0-sm"><i className="fas fa-tasks" /></span>
                   </div>
                   <div className="ncss-col-sm-11 collapse-p0">
                     <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Action Center – OSH Guide
                     </div>
                     <div className="ncss-col-sm-1 ">
                       <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                     </div>
                   </div>
                 </span>
               }
               triggerWhenOpen={
                 <span>
                   <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-tasks"></i></span>
                   </div>
                   <div className="ncss-col-sm-11 collapse-p0">
                     <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Action Center – OSH Guide
                     </div>
                     <div className="ncss-col-sm-1 ">
                       <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                     </div>
                   </div>
                 </span>
               }
               triggerStyle={style}
             >
               <div id="actionCenter"></div>
               {collapsiblesLab.filter(ele =>  ele.containerId === 'actionCenter').map(({fileName, containerId }) => memoizedRenderCollapsibleLab( fileName, containerId))}
             </Collapsible>
           </div>
           </Suspense>

          ) : null}
         
      </div>
    </div>
  ), [isFactoryUser, isSupplierUser, isRslUser, isNloUser, isLabUser, collapsiblesLab,  memoizedRenderCollapsibleLab]);
};



Training.propTypes = {
  isSupplierUser: PropTypes.bool,
  isFactoryUser: PropTypes.bool,
  isRslUser: PropTypes.bool,
  isLabUser: PropTypes.bool,
  isNloUser: PropTypes.bool
};

const mapStateToProps = state => ({
  isSupplierUser: isSupplierUser(state),
  isFactoryUser: isFactoryUser(state),
  isRslUser: isRslUser(state),

  isLabUser: isLabUser(state),
  isNloUser: isNloUser(state)
});

export default compose(
  connect(mapStateToProps)
)(Training);