import React, { Component } from 'react';
import { Collapsible, PageHeader, PageTitle, PdfViewer } from '../../components/index.js';

const style = {
    fontWeight: '800',
    fontSize: '1.8rem',
    lineHheight: '1.5',
    color: '#0078B9'
  };
export class LoginAssistance extends Component{
    render(){
        return(
        <div className="userpage">
        <div className="pdf-align-items pdf-page-bottom-align">
            <PageTitle title="Login Assistance Guide" />
            <PageHeader title="Login Assistance Guide">
            <div className="user-page">
              <i className="fas fa-sign-in-alt"></i>
            </div>
            </PageHeader>
       
        <div>
              <Collapsible className="training_color"

                trigger={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>ENGLISH</span>
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                      <span className='review-padding-left'>ENGLISH</span>
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div id="english"></div>
                <PdfViewer fileName="../loginAssistance/RSL_Testing_Application_Login_Assistance_Guide_Dec_2024_EN.pdf" containerId="english" />
              </Collapsible>
            </div>
            <div>
              <Collapsible className="training_color"

                trigger={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                       <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>INDONESIAN</span> 
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>INDONESIAN</span>
                     </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div id="indonesian"></div>
                <PdfViewer fileName="../loginAssistance/RSL_Testing_Application_Login_Assistance_Guide_Aug_2022_IND.pdf" containerId="indonesian" />
              </Collapsible>
            </div>
            <div>
              <Collapsible className="training_color"

                trigger={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>PORTUGUESE (BR)</span>
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                      <span className='review-padding-left'>PORTUGUESE (BR)</span>
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div id="portuguese"></div>
                <PdfViewer fileName="../loginAssistance/RSL_Testing_Appication_Login_Assistance_Guide_Aug_2022_PTBR.pdf" containerId="portuguese" />
              </Collapsible>
            </div>
            <div>
              <Collapsible className="training_color"

                trigger={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                       <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>SIMPLIFIED CHINESE</span> 
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>SIMPLIFIED CHINESE</span>
                     </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div id="chinese"></div>
                <PdfViewer fileName="../loginAssistance/RSL_Testing_Application_Login_Assistance_Guide_Aug_2022_ZH-CN.pdf" containerId="chinese" />
              </Collapsible>
            </div>
            <div>
              <Collapsible className="training_color"

                trigger={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>VIETNAMESE</span>
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">
                      <span className="training_page text-size-new pr0-sm t" ><i className="fas fa-language"></i></span>
                       <span className='review-padding-left'>VIETNAMESE</span>
                    </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div id="vietnamese"></div>
                <PdfViewer fileName="../loginAssistance/RSL_Testing_Application_Login_Assistance_Guide_Aug_2022_VI.pdf" containerId="vietnamese" />
              </Collapsible>
            </div>
        </div>
        </div>
        );
    }

}
export default LoginAssistance;