import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const PdfPreview = ({ file }) => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }
    
    return (
      <div className='docContainer'>
          <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
          >
              {Array.from(new Array(numPages), (el, index) => (
                  <div key={`page_${index + 1}`} className="docview">
                      <Page 
                          pageNumber={index + 1} 
                          width={window.innerWidth * 0.8} 
                          className="pageView"
                      />
                      {index < numPages - 1 && <hr className='borderDoc' />}
                  </div>
              ))}
          </Document>
  </div>
    );
};

export default PdfPreview;